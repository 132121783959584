.popup-root {
  bottom: auto;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid #d6d6d6;
  @apply flex justify-end;
}

.popup-bg {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
}

.contents-bg {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.contacts-bg {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
}

.popup-root.mobile {
  bottom: 50px;
}

.popup-root-mobile {
  position: absolute !important;
  top: -86px !important;
  left: -1px !important;
  right: -3px !important;
  bottom: 0 !important;
}

.popup-root-mobile-bg {
  position: absolute !important;
  top: -86px !important;
  left: -1px !important;
  right: -3px !important;
  bottom: 0 !important;
  background: white !important;
}

.mobile-image-top {
  position: absolute !important;
  top: -1px !important;
  left: 71px !important;
}

.rounded-mobile {
  border-radius: 2.2rem !important;
}

.popup-root .popup-tab {
  padding-top: 16.14px !important;
  padding-bottom: 16.14px !important;
  padding-left: 12.08px !important;
  padding-right: 12.08px !important;
}

@media (min-width: 360px) and (max-width: 767px) {
  .mobile-popup-tab {
    max-width: 20vw;
  }

  .mobile-popup-tab img {
    max-width: 100%;
    height: auto;
  }

  .popup-tab-mobile > .popup-tab-quote {
    display: none;
  }
}

.promotions-tab .image-preview {
  height: 350px;
}

.popup-root .popup-tab .popup-quote {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.01em !important;
  overflow-wrap: break-word !important;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  font-family: "Outfit", sans-serif !important;
}

.popup-template {
  background-color: #f4f4f4;
  height: 100%;
}

.popup-contact-method {
  overflow-y: scroll;
  background-color: white;
  height: 100%;
}

.cc-tab,
.proof-points-tab,
.testimonials-tab {
  padding: 25px;
}

.contacts-details-container {
  padding: 25px !important;
  @apply h-full;
}

.tab-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.015em;
  @apply mb-5;
}

.proof-points-icon {
  width: 66px;
  height: auto;
}

.proof-points-icon-title {
  font-size: 22px;
}

.popout-image-desktop {
  width: 60px;
  height: 146.15px;
}

.popout-image-mobile {
  width: 93px;
  height: 228px;
}

/* Contact Method */
.tick-popup-container {
  @apply flex flex-wrap;
}

.tick-popup-item {
  @apply w-full flex items-center;
}

.tick-popup-container > div {
  @apply text-sm mb-2;
  flex: 50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
}

/* Multiple Address */
.contact-popout-container {
  @apply flex-col py-4 space-y-5;
}
.contact-popout-item {
  @apply flex-col;
}
.contact-popout-item-subItem {
  @apply flex flex-row items-center pt-1;
}

/* mobile */

.popup-root .popup-tab-mobile {
  margin-top: 460px;
  padding-top: 19.67px !important;
  padding-bottom: 19.67px !important;
  padding-left: 14.75px !important;
  padding-right: 14.75px !important;
}

.m-top-400 {
  margin-top: 350px;
}

.popup-tab-mobile-container {
  max-width: 15vw !important;
}

.popup-tab-mobile-container > img {
  max-width: 100% !important;
  height: auto !important;
}

.icon-circle-mobile {
  width: 33px;
  height: 33px;
  background: #f0f0f0;
}

.icon-circle-desktop {
  width: 33px;
  height: 33px;
  background: #f0f0f0;
}

.popup-root .popup-tab-mobile .popup-quote {
  font-size: 11.476px;
  font-weight: bold;
}

.text-11 {
  font-size: 11px !important;
}

.text-10 {
  font-size: 10px !important;
}

.text-12-5 {
  font-size: 12.5px !important;
}

.icon-size {
  width: 50px !important;
  height: 50px !important;
}

.font-videos {
  font-size: 12px !important;
  line-height: 140% !important;
}

.font-videos-sub {
  font-size: 9px !important;
}

.video-empty {
  height: 175px !important;
  background: #e6e6e6;
}

.promotion-empty {
  height: 347px !important;
  background: #e6e6e6;
}

.promotion-color {
  color: #b6b6b6 !important;
  font-size: 13px;
}

.logo-empty {
  display: grid;
  height: 66px !important;
  background: #e6e6e6;
  color: #c4c4c4;
}

/* contact details mobile */
.standard-empty {
  @apply bg-default mt-4 grid;
}

.standard-empty-no-bg {
  @apply mt-4 grid;
}

.player .vp-video-wrapper .vp-preview {
  background-size: cover !important;
}

.react-player__preview {
  background-size: cover !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.map-empty {
  min-height: 161px !important;
  @apply bg-default mt-5 grid;
}

.map-empty-desktop {
  min-height: 161px !important;
  @apply bg-default mt-5 grid;
}

.lead-empty {
  height: 161px !important;
  @apply bg-default grid;
}

.addresses {
  font-size: 11.5px;
  line-height: 150%;
  letter-spacing: 0.015em;
  color: #202020;
}

.hidden-scrollable-bar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.hidden-scrollable-bar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
