.mobile-responsive-container {
  @apply w-full 3md:flex 3md:flex-1 3md:flex-row 3md:justify-between 3md:space-x-2.5;
}

.mobile-responsive-left {
  @apply w-full 8md:w-571;
}

.mobile-responsive-right {
  @apply w-full 3md:w-1066;
}

.nav-responsive {
  @apply w-full 3md:flex;
}

.nav-logo-responsive {
  @apply pr-10 sm:pb-5 lg:pr-22 2lg:pr-40;
}

.padding-desktop-responsive {
  @apply pl-0 5md:pl-8 3md:pl-10 xl:pl-20;
}

.padding-mobile-responsive {
  @apply pl-0 5md:pl-8 3md:pl-56;
}

.cc-preview {
  font-family: "Outfit", sans-serif !important;
  line-height: 1.5;
}

.cc-popout-item-secondary:hover {
  right: 0 !important;
  transition: 0.5s !important;
}

.transition {
  transition: 0.5s !important;
}

.help-popup-mobile {
  position: absolute !important;
  top: 0.25rem;
  left: 40%;
}

.help-popup-mobile-sticky {
  position: absolute !important;
  top: -44px;
  left: 25%;
}

.help-popup-desktop {
  position: absolute !important;
  top: 0.25rem;
  left: 10%;
}

/* preview */
.preview-root {
  bottom: auto;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0px);
  @apply flex justify-end;
}

.preview-root-mobile {
  bottom: auto;
  align-items: center;
  background-color: #434261;
  @apply flex justify-center;
}

.preview-root-left {
  bottom: auto;
  align-items: center;
  @apply absolute flex justify-start;
}

.mobile-padding {
  padding-top: 56px;
  padding-bottom: 19px;
  padding-left: 31px;
  padding-right: 22px;
}

.mobile-rounded {
  border-bottom-right-radius: 34px;
  border-bottom-left-radius: 34px;
}

.icon-svg {
  height: 16.02px !important;
  width: 16px !important;
}

.popup-icon-above {
  margin-bottom: 15px !important;
  width: 43px !important;
  height: 43px !important;
  @apply bg-white flex;
}

.popup-icon-below {
  margin-top: 15px !important;
  width: 43px !important;
  height: 43px !important;
  @apply bg-white flex;
}

.hover-effects:hover #parent-hover,
#parent-hover:hover {
  right: 0 !important;
  transition: 0.5s !important;
}

.preview-mobile-masked {
  position: absolute !important;
  top: -27px !important;
  bottom: 0 !important;
  border-radius: 30px;
}

.upper {
  @apply hidden 2md:block absolute bg-transparent left-0 h-screen w-full cursor-default;
  z-index: -1;
  top: -100vh;
}

.lower {
  @apply hidden 2md:block absolute bg-transparent left-0 h-screen w-full cursor-default;
  z-index: -1;
  bottom: -100vh;
}
