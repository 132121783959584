/* screens: {
	'sm': '320px',
	'md': '768px',
	'2md': '1024px',
	'lg': '1440px',
	'xl': '1536px',
	'2xl': '1920px',
	'3xl': '2560px',
}*/

.profile-short-details-wrapper .profile-image-container {
  @apply w-10 h-10
        md:w-12 md:h-12
        lg:w-16 lg:h-16
        2xl:w-20 2xl:h-20
        3xl:w-28 3xl:h-28;
}

.profile-short-details-wrapper .profile-image {
  @apply w-full h-full rounded-full shadow;
}

.profile-short-details-wrapper .profile-details-container {
  @apply flex flex-col justify-center;
}

.profile-short-details-wrapper .profile-details-container .name {
  @apply font-bold
        text-sm
        md:text-base
        lg:text-xl
        2xl:text-3xl
        3xl:text-4xl;
}

.profile-short-details-wrapper .profile-details-container .subtitle {
  @apply font-light
        text-xs
        md:text-sm
        lg:text-base
        2xl:text-2xl
        3xl:text-3xl;
}
