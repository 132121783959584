.settings-wrapper {
  @apply h-full mb-16 bg-white border border-thBorder p-6 flex flex-col space-y-4;
}

.settings-time-container {
  @apply flex space-x-2 items-center;
}

.settings-time-row {
  @apply flex flex-row items-center justify-between border border-thBorder rounded-sm;
}

.settings-input-arrows {
  @apply flex flex-col h-10 justify-between border-l border-thBorder;
}

.settings-input-icon {
  @apply px-1.5 h-full flex items-center;
}
.settings-time {
  border: none !important;
  text-align: center !important;
}
/* input.settings-time[type=number]::-webkit-inner-spin-button, 
input.settings-time[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}
input.settings-time[type=number] {
    position: relative; 
}

input[type=number] {
    -moz-appearance: textfield;
} */

input.settings-time[type="number"]::-webkit-outer-spin-button,
input.settings-time[type="number"]::-webkit-inner-spin-button {
  /* -webkit-appearance:caret;
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: white; */
  -webkit-appearance: none;
  margin: 0;
}

input.settings-time[type="number"] {
  -moz-appearance: textfield;
}
