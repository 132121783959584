.preview-lead-details {
  bottom: auto;
  @apply flex justify-end;
  z-index: 99999999999999;
}

.lead-details-content-item {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.16);
  @apply h-screen bg-white w-500 px-8 py-7;
}
