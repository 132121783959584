@tailwind base;
@tailwind components;
@tailwind utilities;

.preview-iframe {
  pointer-events: none;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: none !important;
}

.google-autocomplete-input [type="text"]:focus {
  --tw-ring-color: transparent !important;
}

.site-sidabar {
  width: 293px !important;
}

.logo-bar {
  margin-left: 40px;
  margin-bottom: 30px;
}

.logo-bar svg {
  width: 189px;
}

.main-wrapper {
  width: 1425px;
  max-width: 100%;
}

.popout-wrapper {
  width: 1425px;
  max-width: 100%;
}

.campaing-website {
  width: 147px !important;
  height: 102px !important;
}

.sidebar-link {
  margin-left: 40px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.04em;
}

.sidebar-link:hover svg path,
.sidebar-link.is-active svg path {
  fill: white;
}

.sidebar-link-stroke {
  margin-left: 40px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.04em;
}

.sidebar-link-stroke:hover svg path,
.sidebar-link-stroke.is-active svg path {
  stroke: white;
}

.sidebar-link-stroke:hover svg defs clipPath rect {
  fill: white;
}

.sidebar-help-icon {
  margin-left: 40px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.04em;
}

.sidebar-icon-bg {
  width: 40px;
  height: 40px;
}

.text-decoration {
  text-decoration-color: #ec5627 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 2px !important;
}

.text-decoration-20 {
  text-underline-offset: 20px !important;
}

.text-decoration-30 {
  text-underline-offset: 30px !important;
}

.flex-middle {
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

.site-header {
  padding: 0 29px;
}

.site-header .profile-button {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  /*  background-color: #ffc145;*/
}

.profile-name .profile-name-text {
  /* font-family: Larsseit; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.015em;
}

.alert {
  @apply bg-secondary;
  @apply justify-between;
  @apply flex;
  @apply items-center;
  @apply gap-4;
  @apply text-white;
  padding: 17px 29px;
}

.alert-error {
  @apply bg-red;
  @apply justify-between;
  @apply flex;
  @apply items-center;
  @apply text-white;
  padding: 17px 29px;
}

.main-wrapper {
  padding: 17px 29px;
}

.alert svg path {
  fill: white;
}

.alert-button {
  width: 27px;
  height: 27px;
  border-radius: 100%;
  @apply bg-secondaryDark;
}

.close {
  @apply w-5 h-5 opacity-40;
  cursor: pointer;
  content: url("./assets/svg/close.svg");
}

.close-video {
  cursor: pointer;
  content: url("./assets/svg/close-video.svg");
  width: 12px;
  height: 12px;
}

.drop-shadow-2 {
  filter: drop-shadow(0px 16px 32px rgba(35, 23, 5, 0.26)) !important;
}

.bg-size-full {
  background-size: 100% 100%;
}

.campaign-left-max {
  max-width: 571px;
}

.campaign-right-max {
  height: 689px !important;
  max-width: 1066px;
  max-height: 689px;
}

.campaign-right-mobile-max {
  height: 792.17px !important;
  max-width: 391.5px;
  max-height: 792.17px;
}

.round-mobile-bottom {
  border-bottom-right-radius: 2.2rem !important;
  border-bottom-left-radius: 2.2rem !important;
}

.box-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12) !important;
}

.box-shadow-2 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
}

.image-view {
  width: 91px !important;
  height: 184px !important;
}

.image-view-popout-tab {
  display: flex;
  width: 180px !important;
  height: 180px !important;
  justify-content: center;
}

.image-view-height-popout-tab {
  height: 180px !important;
}

.image-view-height {
  height: 184px;
}

.image-view-height-mobile {
  height: 140px !important;
}

.image-view-promotion {
  width: 146.51px !important;
  height: 215px !important;
}

.thumbnail-view {
  width: 100px !important;
  height: 68px !important;
}

.thumbnail-view-height {
  height: 68px !important;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #000000;
  border-radius: 500px;
  cursor: pointer;
}

.empty-layout-responsive {
  @apply pl-8 pr-8 md:pl-29 8md:pr-0;
  @apply pt-9.5;

  -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.btn-styles {
  width: 72.83px !important;
  height: 72.83px !important;
  @apply bg-stlBtnBg rounded hover:border-secondary;
}

.btn-image-styles {
  width: 43.13px !important;
  height: 43.13px !important;
}

.btn-color-picker {
  width: 28px !important;
  height: 28px !important;
  @apply bg-white !important;
}

.btn-primary-picker {
  width: 28px !important;
  height: 28px !important;
}

.btn-primary-picker-active {
  width: 24px !important;
  height: 24px !important;
}

.btn-color-active {
  @apply ring-1 ring-stlBtnOutsideBorder ring-offset-4 ring-offset-white;
}

.btn-remove-alternative {
  width: 21px !important;
  height: 21px !important;
  /*background: #eaeaea; */
  background-color: #ffffff !important;
  border-width: 1px;
  border-color: #eaeaea;
}

.btn-remove {
  width: 21px !important;
  height: 21px !important;
  background: #f3f4f5 !important;
}

.text-13 {
  font-size: 13px !important;
  line-height: 150% !important;
  /* or 20px */
  letter-spacing: 0.015em !important;
}

.text-15 {
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.015em;
}

.testimonial-upload {
  width: 30px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  @apply rounded-full grid absolute right-0 top-1;
}

.popup-contact {
  position: absolute;
  top: 25%;
  left: 470px;
}

.ab-test {
  width: 40px;
  height: 38.59px;
  @apply flex rounded-full items-center justify-center text-lg font-bold text-white;
}

.ab-test-block {
  width: 40px;
  height: 38.59px;
  @apply flex rounded-full items-center justify-center text-lg font-bold text-white;
}

.popout-card {
  @apply rounded-md bg-white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

/* color picker overwrite some css */
.rcp-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rcp-saturation {
  border-radius: 8px !important;
}

.input-colors {
  width: 38% !important;
}

/* alter css cropper */
.cropper-view-box {
  outline: 1px dashed #fff !important;
  outline-color: rgba(51, 153, 255, 0.75);
}

.cropper-line {
  background-color: #fff !important;
}

.cropper-point {
  background-color: #fff !important;
}

.cropper-point.point-se::before {
  background-color: #fff !important;
}

.magic-dev {
  position: absolute !important;
  left: 1120px !important;

  -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

/* 445.5px */
.magic-dev-mobile {
  position: absolute !important;
  left: 445.5px !important;

  -webkit-transition: background-color 1000ms linear;
  -moz-transition: background-color 1000ms linear;
  -o-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

/* button animation */
.animate-btn {
  transition: 0.5s !important;
  cursor: pointer !important;
}

.tooltip-btn {
  transition: 0.5s !important;
  cursor: pointer !important;
  animation: bounce 2s ease infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animate-btn-color {
  transition: 0.5s !important;
  cursor: pointer !important;
}

.animate-btn:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3) !important;
}

.icon-selector-btn {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2) !important;
}

.animate-btn-color:hover {
  transform: translateY(-5px) !important;
}

/* modal animation */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* popup animation */
.popup-content {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  /*  */
  -webkit-animation-duration: 600ms;
  animation-duration: 600ms;
  /*  */
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/* desktop animation */
.slideIn {
  position: relative;
  right: -740px;
  width: 370px;
  -webkit-animation: slide 1s forwards;
  /* -webkit-animation-delay: 2s; */
  animation: slide 1s forwards;
  /* animation-delay: 2s; */
}

/* start: template */
.slideIn-template {
  position: relative;
  right: -740px;
  width: 370px;
  -webkit-animation: slide 1s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 1s forwards;
  animation-delay: 0.2s;
}

.slideIn-div-template {
  position: relative;
  right: -740px;
  width: 61px;
  -webkit-animation: slide 1s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 1s forwards;
  animation-delay: 0.2s;
}

.slideIn-image-template {
  position: relative;
  right: -740px;
  -webkit-animation: slide 1s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 1s forwards;
  animation-delay: 0.2s;
}

/*end: template */

.slideIn-div {
  position: relative;
  right: -740px;
  width: 61px;
  -webkit-animation: slide 1s forwards;
  animation: slide 1s forwards;
}

@keyframes saturationFade {
  50% {
    filter: saturate(0.7);
  }
}

.slideIn-colour-effects {
  -webkit-animation:
    slide 1s forwards,
    saturationFade 2s 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation:
    slide 1s forwards,
    saturationFade 2s 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.slideIn-colour-effects:hover {
  /* Stop only the 'pulse' animation on hover by setting its name to 'none' */
  -webkit-animation:
    slide 1s forwards,
    none;
  animation:
    slide 1s forwards,
    none;
}

.slideIn-pulse-effects {
  -webkit-animation:
    slide 1s forwards,
    pulse-white 1.6s infinite;
  animation:
    slide 1s forwards,
    pulse-white 1.6s infinite;
}

.colour-effects {
  -webkit-animation: pulse 2s 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.colour-effects:hover {
  /* Stop only the 'pulse' animation on hover by setting its name to 'none' */
  -webkit-animation: none;
  animation: none;
}

.pulse-effects {
  -webkit-animation: pulse-white 1.6s infinite;
  animation: pulse-white 1.6s infinite;
}

.slideIn-image {
  position: relative;
  right: -740px;
  -webkit-animation: slide 1s forwards;
  animation: slide 1s forwards;
  display: flex;
  justify-content: flex-end;
}

.slideIn-div-content {
  position: relative;
  right: -740px;
  width: 61px;
  -webkit-animation: slide 1s forwards;
  animation: slide 1s forwards;
}

.slideIn-image-content {
  position: relative;
  right: -740px;
  width: 60px;
  -webkit-animation: slide 1s forwards;
  animation: slide 1s forwards;
}

/* mobile animation */
.slideIn-image-mobile {
  position: relative;
  right: -500px;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

.slideIn-div-mobile {
  position: relative;
  right: -370px;
  width: 94px;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

/* tab animation */
.tab-animation {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.tab-animation:before,
.tab-animation:after {
  height: 4px;
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  /* background-color: #9b59b6; */
  width: 0;
  @apply bg-secondary;
}

.tab-animation:before {
  bottom: 0;
  right: 0;
}

.tab-animation:after {
  bottom: -4px;
  left: 0;
}

.tab-animation:hover:after,
.tab-animation.active:after {
  width: 100%;
}

/* tab animation black */
.tab-animation-black {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.tab-animation-black:before,
.tab-animation-black:after {
  height: 4px;
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  /* background-color: #9b59b6; */
  width: 0;
  @apply bg-black;
}

.tab-animation-black:before {
  bottom: 0;
  right: 0;
}

.tab-animation-black:after {
  bottom: -4px;
  left: 0;
}

.tab-animation-black:hover:after,
.tab-animation-black.active:after {
  width: 100%;
}

.filter-btn {
  @apply relative inline-flex justify-center px-4 py-2 text-sm leading-5 cursor-pointer whitespace-nowrap text-center;
}

.statistics {
  height: 119px !important;
}

.stats-data {
  font-size: 32px !important;
  line-height: 130% !important;
}

.table-number {
  width: 31px !important;
  height: 31px !important;
  @apply rounded-full bg-default flex items-center justify-center text-lg font-bold;
}

.table-website {
  width: 159px !important;
  height: 107.87px !important;
}

.dounut-text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: calc(50% - 32px);
  left: 0;
  z-index: 40;
}

.span-default {
  background: rgba(236, 86, 39, 0.12) !important;
  border-radius: 5px !important;
  padding-left: 9.5px !important;
  padding-right: 9.5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.plan-prices {
  font-size: 32px !important;
  line-height: 150% !important;
  /* identical to box height, or 48px */
  letter-spacing: 0.015em !important;
}

.pricing-card {
  border-radius: 10px !important;
}

.pricing-card-border {
  border: 0.5px solid #cdcdcd !important;
}

.text-28px {
  font-size: 28px !important;
  line-height: 150% !important;
  letter-spacing: 0.015em !important;
}

.text-56px {
  font-size: 56px !important;
  line-height: 150% !important;
  letter-spacing: 0.015em !important;
}

/* banner animation */
.banner-btn {
  transition: 0.25s !important;
}

.banner-btn:hover,
.banner-btn:hover {
  box-shadow: 0 0.5em 0.5em -0.4em #000 !important;
  transform: translateY(-0.25em) !important;
  font-weight: bold !important;
}

.close-hover:hover {
  @apply bg-primary;
}

.close-hover:hover .svg-icon,
.svg-icon:hover {
  @apply text-white;
}

/* pulse animation */
.pulse.white {
  background: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/* end: pulse animation */

/* pagination css */
.pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 2px;
  border: 1px solid #dfdfdf;
  padding: 5px 13px;
  border-radius: 5px;
  color: #6c757d;
  text-decoration: none;
  background-color: white;
}

.page-item a.page-link:hover {
  color: #ffffff;
  background-color: #28265b;
}

.page-item.active .page-link {
  color: #ffffff;
  background-color: #28265b;
}

.page-item.disabled .page-link {
  color: #6c757d;
  cursor: not-allowed !important;
  opacity: 0.5;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* pagination end css */

/* Breadcrums steps css */
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 12px;
  font-weight: bold;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #d9d9d9;
  margin-left: 12%;
  width: 75%;
  top: 10px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #d9d9d9;
  margin-left: 12%;
  width: 75%;
  top: 10px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19.74px;
  height: 19.74px;
  border-radius: 50%;
  background: #d9d9d9;
  margin-bottom: 6px;
  color: white;
}

.stepper-item.active {
  @apply font-bold;
}

.stepper-item.completed .step-counter,
.stepper-item.active .step-counter {
  @apply bg-secondary;
}

.stepper-item .step-name {
  @apply text-breadcrums;
}

.stepper-item:hover .step-name {
  @apply text-secondary;
}

.stepper-item:hover .step-counter {
  @apply bg-secondary;
}

.stepper-item.active .step-name {
  @apply text-secondary;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ec5627;
  margin-left: 12%;
  width: 75%;
  top: 10px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

/* end: Breadcrums steps css */

.profile-image {
  @apply w-full h-full rounded-full shadow;
}

/* date range */

.rdrDefinedRangesWrapper {
  display: none;
}

.calendarElement {
  position: absolute;
  left: 100%;
  transform: translateX(-100%);
  top: 50px;
  border: 1px solid #ccc;
  z-index: 9999;
}

/* wysiwyg editor*/
.ql-editor {
  min-height: 38px !important;
  width: 100% !important;
  padding-top: 4px !important;
  padding-bottom: 12px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.ql-toolbar {
  border-left: 1px solid #dfdfdf !important;
  border-top: 1px solid #dfdfdf !important;
  border-right: 1px solid #dfdfdf !important;
  border-bottom: 0px solid #dfdfdf !important;
  border-radius: 4px 4px 0px 0px !important;
  background: #ffffff !important;
}

.ql-bold .ql-stroke,
.ql-italic .ql-stroke,
.ql-underline .ql-stroke,
.ql-underline .ql-fill,
.ql-formats .ql-list .ql-stroke {
  stroke: #000 !important;
  opacity: 0.5 !important;
}

/* active */
.ql-bold.ql-active,
.ql-italic.ql-active,
.ql-underline.ql-active {
  background-color: #efefef !important;
  margin-right: 8px !important;
}

.ql-bold,
.ql-italic,
.ql-underline {
  margin-right: 8px !important;
}

.ql-active .ql-stroke,
.ql-active .ql-fill,
.ql-list.ql-active .ql-stroke {
  stroke: #000 !important;
  opacity: 1 !important;
}

/* end active */

.ql-editor.ql-blank::before {
  color: #aaaaaa !important;
  font-style: normal !important;
}

.ql-container {
  border-left: 1px solid #dfdfdf !important;
  border-bottom: 1px solid #dfdfdf !important;
  border-right: 1px solid #dfdfdf !important;
  border-top: 0px solid #dfdfdf !important;
  border-radius: 0px 0px 4px 4px !important;
  font-family: inherit !important;
  font-size: 16px !important;
}

/* end wysiwyg */
/* start draft wysiwyg */
.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.custom-toolBar {
  padding: 0 !important;
  border: 0 !important;
  margin-bottom: 0 !important;
}

.draft-custom-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #efefef !important;
  background-color: #efefef !important;
}

.rdw-option-active {
  box-shadow: 1px 1px 0px #efefef inset !important;
  background-color: #efefef !important;
}

.bold-custom,
.italic-custom,
.underline-custom {
  border: 0 !important;
  margin: 0 4px !important;
  padding: 4px !important;
}

.DraftEditor-root {
  overflow: hidden !important;
}

/* end draft wysiwyg */
/* wilson fixed popout in the builder
.builder-popout-content {
  position: sticky !important;
}*/
/* wilson fixed popout in the builder */
