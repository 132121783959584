.page-wrapper {
  @apply sm:pb-10 md:pb-0;
}

/* Column Section */
.column-title {
  @apply text-center pb-6 md:pb-3 2md:pb-6 lg:pb-10 2xl:pb-12 3xl:pb-16 text-[2rem];
}

.column-title small {
  @apply text-lg;
}

.column-subtitle {
  @apply text-center pb-6 md:pb-3 2md:pb-6 lg:pb-10 2xl:pb-12 3xl:pb-16 text-xs 2md:text-sm lg:text-sm 3xl:text-lg;
}

.column-container {
  @apply px-6 2md:px-16 lg:px-24 2xl:px-40 3xl:px-52;
}

.input-container {
  @apply flex-1 overflow-hidden p-1 mb-3;
}

.input-container-2 {
  @apply flex-1 overflow-hidden p-1 mb-1.5;
}

.input-container-3 {
  @apply flex-1 overflow-hidden p-1;
}

.input-container-map {
  @apply flex-1 p-1 mb-1.5;
}

.input-container-1 {
  @apply flex-1 overflow-hidden p-1 mb-0.5;
}

.checkbox-container {
  @apply mb-8 mt-3 pl-1 flex flex-row justify-between items-center;
}

.forgot-password-link {
  @apply text-xs text-secondary cursor-pointer;
}

.no-account-text {
  @apply text-center font-light text-xs;
}

.no-account-link {
  @apply text-secondary cursor-pointer;
}

.checkbox-text {
  @apply text-xs 2md:text-sm 2xl:text-lg 3xl:text-lg font-light;
}
