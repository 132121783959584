.input-wrapper {
  @apply relative;
}

.input {
  @apply w-full rounded mt-1;
}

.icon-input {
  @apply absolute text-white h-full w-full p-1 right-0;
}

.input-error-message {
  @apply text-red text-xs mb-0.5;
}

.input-class {
  @apply block w-full appearance-none outline-none;
}

/*  lg:text-lg
        2xl:text-xl
        3xl:text-2xl */

/*    lg:text-xs
        2xl:text-sm
        3xl:text-lg */

.input-label {
  @apply absolute top-1.5 pl-4 text-xs opacity-50;
}

.input-label-xs {
  @apply absolute top-1.5 pl-4 text-xs opacity-50
        lg:text-xs;
}

.input-label-upper {
  @apply pb-4 text-base;
}

.validation-container {
  @apply absolute right-4 rounded-full h-5 w-5
        lg:h-6
        lg:w-6
        top-3.5
        2xl:top-5;
}

/* overwrite class input */
.input-label-twin {
  @apply absolute left-0 top-1.5 text-xs opacity-50
        lg:text-xs;
}

.input-twin-upper {
  @apply pb-1 pt-6 px-4
    2xl:pt-6 rounded-t;
  border-color: #dfdfdf !important;
  border-width: 1px 1px 0px 1px !important;
}

.input-twin-upper-error {
  @apply pb-1 pt-6 px-4
    2xl:pt-6 rounded-t;
  border-color: #ff0000 !important;
  border-width: 1px 1px 0px 1px !important;
}

.input-twin-lower {
  @apply pb-1 pt-6 px-4
    2xl:pt-6 rounded-b;
  border-color: #dfdfdf !important;
  border-width: 1px !important;
}

.input-twin-lower-error {
  @apply pb-1 pt-6 px-4
    2xl:pt-6 rounded-b;
  border-bottom-color: #ff0000 !important;
  border-left-color: #ff0000 !important;
  border-right-color: #ff0000 !important;
  border-width: 1px !important;
}

.input-twin-lower-error-full {
  @apply pb-1 pt-6 px-4
    2xl:pt-6 rounded-t;
  border-color: #ff0000 !important;
  border-width: 1px !important;
}

.input-twin-upper-error-full {
  @apply pb-1 pt-6 px-4
    2xl:pt-6 rounded-b;
  border-color: #ff0000 !important;
  border-width: 1px !important;
}

.input-twin-upper:focus-visible {
  outline: none !important;
  border-width: 1px 1px 0px 1px !important;
}

.input-twin-lower:focus-visible {
  outline: none !important;
  border-width: 1px !important;
}

.input-twin-lower:focus-within ~ label,
.input-twin-upper:focus-within ~ label {
  @apply text-secondary opacity-100;
}

.masked-button {
  @apply w-8 h-8 border border-borderInput rounded-full absolute right-0.5 top-9;
  background-color: #ffffff !important;
}

.masked-button-input {
  @apply w-8 h-8 border border-borderInput rounded-full absolute right-0 top-3;
  background-color: #ffffff !important;
}

.reload-button-input {
  @apply w-7 h-7 border border-borderInput rounded-full absolute right-2 top-2;
  background-color: #ffffff !important;
}

.reload-button-go {
  @apply absolute right-2 top-3.5 text-sm font-bold;
}

.extra-label {
  color: #808080 !important;
  @apply text-sm absolute top-3 right-12;
}

.extra-add-button {
  color: #808080 !important;
  @apply text-sm absolute top-3 left-32;
}

.input-preview {
  border-color: #dfdfdf !important;
  border-width: 1px !important;
  border-radius: 5px;
  height: 32px;
}

.input-preview-text-area {
  border-color: #dfdfdf !important;
  border-width: 1px !important;
  border-radius: 5px;
  height: 56px;
}

.input-preview:focus-visible,
.input-preview-text-area:focus-visible {
  outline: none !important;
  border-width: 1px !important;
}

.input-list-container {
  max-height: 275px !important;
  @apply absolute mt-2.5 w-full rounded bg-white ring-1 ring-thBorder overflow-y-auto;
}

.input-list {
  @apply cursor-pointer select-none py-2 px-4 hover:bg-gray;
}

.input-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.input-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.input-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.input-preview-final {
  border-width: 2px !important;
  border-color: #dbdbdb !important;
  padding-top: 14.85px !important;
  padding-left: 18.45px !important;
  padding-right: 34.65px !important;
  padding-bottom: 14.85px !important;
}

.input-preview-final:focus-visible {
  outline: none !important;
  border-width: 2px !important;
}

.masked-input {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;

  @apply block w-full appearance-none outline-none bg-transparent;
}

.masked-input {
  @apply rounded;
  border-color: #dfdfdf !important;
  border-width: 1px !important;
}

.masked-input:focus-visible {
  outline: none !important;
  border-color: #2563eb !important;
  border-width: 1px !important;
}
