@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdblack.eot");
  src:
    url("./assets/fonts/shellypalmercircularstdblack.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdblack.woff2") format("woff2"),
    url("./assets/fonts/shellypalmercircularstdblack.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdblack.ttf") format("truetype"),
    url("./assets/fonts/shellypalmercircularstdblack.svg#shellypalmercircularstdblack")
      format("svg");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdbold.eot");
  src:
    url("./assets/fonts/shellypalmercircularstdbold.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdbold.woff2") format("woff2"),
    url("./assets/fonts/shellypalmercircularstdbold.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdbold.ttf") format("truetype"),
    url("./assets/fonts/shellypalmercircularstdbold.svg#shellypalmercircularstdbold")
      format("svg");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdbook.eot");
  src:
    url("./assets/fonts/shellypalmercircularstdbook.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdbook.woff2") format("woff2"),
    url("./assets/fonts/shellypalmercircularstdbook.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdbook.ttf") format("truetype"),
    url("./assets/fonts/shellypalmercircularstdbook.svg#shellypalmercircularstdbook")
      format("svg");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdbookitalic.eot");
  src:
    url("./assets/fonts/shellypalmercircularstdbookitalic.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdbookitalic.woff2")
      format("woff2"),
    url("./assets/fonts/shellypalmercircularstdbookitalic.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdbookitalic.ttf")
      format("truetype"),
    url("./assets/fonts/shellypalmercircularstdbookitalic.svg#shellypalmercircularstdbookitalic")
      format("svg");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Circular Std";
  src: url("./assets/fonts/shellypalmercircularstdmedium.eot");
  src:
    url("./assets/fonts/shellypalmercircularstdmedium.eot")
      format("embedded-opentype"),
    url("./assets/fonts/shellypalmercircularstdmedium.woff2") format("woff2"),
    url("./assets/fonts/shellypalmercircularstdmedium.woff") format("woff"),
    url("./assets/fonts/shellypalmercircularstdmedium.ttf") format("truetype"),
    url("./assets/fonts/shellypalmercircularstdmedium.svg#shellypalmercircularstdmedium")
      format("svg");
  font-style: normal;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Circular Std";
  @apply bg-siteBg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

[type="checkbox"] {
  color: #ec5627;
  outline: none;
}

.alternativ-checkbox {
  border-color: #dfdfdf !important;
  border-width: 1px !important;
}

input[type="text"],
input[type="password"],
input#react-select-address-input,
textarea[type="text"],
input[type="email"] {
  @apply rounded pb-1 pt-5 px-4
    2xl:pt-8;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

input#react-select-pin-input {
  width: 100% !important;
}

.input-phone input {
  @apply rounded py-2.5 px-4;
  border-color: #dfdfdf !important;
  border-width: 1px !important;
}

.input-phone.error-phone input {
  border-color: red !important;
  border-width: 1px !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-number {
  @apply rounded pb-1 pt-5 px-4 2xl:pt-8;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder,
input::placeholder,
#react-select-3-input::placeholder,
input[type="email"]::placeholder,
textarea[type="text"]::placeholder {
  color: #aaaaaa !important;
}

input#react-select-address-input {
  width: 100% !important;
  box-shadow: none;
}

input:focus-visible,
textarea:focus-visible,
.react-select-address-input:focus-visible,
select:focus-visible {
  outline: none !important;
  /* border-width: 0px !important; */
}

textarea,
input,
input#react-select-address-input {
  outline: none !important;
}

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  /* @apply transform scale-75 -translate-y-6; */
}

input[type="text"]:focus-within ~ label,
input[type="password"]:focus-within ~ label,
input[type="email"]:focus-within ~ label,
textarea:focus-within ~ label,
input#react-select-address-input:focus-within ~ label {
  @apply text-secondary opacity-100;
}

.checkbox-disable-mobile:focus {
  outline: none !important;
  box-shadow: none;
}

.custom-scroll::-webkit-scrollbar {
  width: 13px;
  height: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.mobile-scroll::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}
.mobile-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.mobile-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.div-input-scroll::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.div-input-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.div-input-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.input-range {
  @apply rounded-full w-full h-1.5;
  -webkit-appearance: none;
  outline: none;
}

.input-range::-webkit-slider-thumb {
  @apply rounded-full;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  cursor: pointer; /* Cursor on hover */
}

.input-range-slider {
  @apply rounded-full w-full h-1.5;
  -webkit-appearance: none;
  outline: none;
}

.input-range-slider::-webkit-slider-thumb {
  @apply rounded-full;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #ec5627;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  cursor: pointer; /* Cursor on hover */
}

.input-range-impressions {
  @apply rounded-full w-full h-3;
  -webkit-appearance: none;
  outline: none;
}

.input-range-impressions::-webkit-slider-thumb {
  @apply rounded-full;
  -webkit-appearance: none !important;
  appearance: none !important;
}

.custom-slider {
  @apply rounded-md w-full;
  height: 59px !important;
  -webkit-appearance: none;
  outline: none;
}

.custom-slider::-webkit-slider-thumb {
  @apply rounded-full;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 40px; /* Set a specific slider handle width */
  height: 40px; /* Slider handle height */
  background-image: url("./assets/svg/arrow-slider.svg");
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  cursor: ew-resize; /* Cursor on hover */
  z-index: 999 !important;
  position: relative !important;
}

.referral-remove {
  position: absolute;
  top: -10px;
  right: -32px;
  z-index: 10;
  cursor: pointer;
}

/* contenteditable */
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: #aaaaaa !important;
}

[contenteditable="true"]:focus-within ~ label {
  @apply text-secondary opacity-100;
}

@import "./assets/css/index.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
