.form-divider-container {
  @apply flex flex-row w-full items-center my-3 lg:my-6 2xl:my-8 3xl:my-10;
}

.form-divider-line {
  @apply border-t border-lightGray flex-1;
}

.form-divider-text {
  @apply text-gray text-xs 2md:text-sm lg:text-lg 2xl:text-xl 3xl:text-2xl;
}
