.select-image {
  width: 144px !important;
  height: 97.69px !important;
  @apply rounded-md;
}

.ab-test-select {
  width: 40px;
  height: 38.59px;
  @apply flex rounded-full items-center justify-center text-lg font-bold absolute -left-5;
  /* background-color: #f0f0f0 !important; */
}

.select-wrapper {
  @apply flex w-full items-center justify-center py-2.5;
}

.no-padding-wrapper {
  @apply flex w-full items-center justify-center;
}

.select {
  @apply flex w-full items-center justify-between rounded py-2 px-4 ring-1;
  background-color: #ffffff !important;
}

.select-sort {
  @apply flex w-full items-center justify-between rounded  py-2 px-4 ring-1;
  background-color: #ededed !important;
}

.select-sort-lead {
  @apply flex w-full items-center justify-between rounded  py-2 px-4 ring-1;
  background-color: #fff !important;
}

.select-big {
  height: 140px !important;
  @apply relative flex w-full items-center justify-between rounded py-2 px-4 ring-1;
  background-color: #ffffff !important;
}

.select-list-container {
  max-height: 275px !important;
  @apply absolute mt-2.5 w-full rounded-md bg-white ring-1 ring-thBorder overflow-y-auto;
}

.select-div-container {
  max-height: 312px !important;
  border: 1px solid rgba(196, 196, 196, 0.3) !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12) !important;
  @apply absolute mt-2.5 w-full bg-white;
}

.select-div-border {
  border: 1px solid #e5e5e5 !important;
}

.select-list {
  @apply cursor-pointer select-none py-2 px-4 hover:bg-gray;
}

.select-list-popout {
  @apply flex items-center cursor-pointer select-none py-2 px-4 hover:bg-gray;
}

.select-div {
  max-height: 246px !important;
  @apply overflow-y-auto;
}
.select-div-list {
  @apply cursor-pointer select-none py-1 pl-9 pr-4 hover:bg-gray;
}

.select-button {
  @apply cursor-pointer select-none py-4 pl-9 pr-4 text-secondary text-sm font-bold;
}

.list-active {
  @apply bg-gray;
}

/* select scroll */
.select-scroll::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
.select-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.select-scroll::-webkit-scrollbar-thumb {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.popout-scroll::-webkit-scrollbar {
  width: 10px;
  height: 3px;
}
.popout-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.popout-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.select-preview {
  border-width: 2px !important;
  border-color: #dbdbdb !important;
  padding-top: 14.85px !important;
  padding-left: 18.45px !important;
  padding-right: 18.45px !important;
  padding-bottom: 14.85px !important;
}

.select-preview-items {
  padding-left: 18.45px !important;
  padding-right: 18.45px !important;
}

.select-preview-items-container {
  max-height: 205px !important;
  border-width: 1px !important;
  border-color: #dbdbdb !important;
  @apply absolute mt-2 w-full rounded bg-white overflow-y-auto z-50;
}
