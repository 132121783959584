.tests-wrapper {
  @apply h-full;
}
.title-container .title {
  @apply mr-5 text-xl;
}
.tests-wrapper .header-wrapper {
  @apply flex items-center mb-4 bg-white  border border-thBorder;
}
.tests-wrapper .content-wrapper {
  @apply flex items-center mb-4 bg-white  border border-thBorder;
}
.tests-wrapper .header-container {
  @apply inline-flex items-center justify-between w-full px-6 py-5 border-b border-thBorder;
}
.tests-wrapper .content-container {
  @apply w-full;
}
.tests-wrapper .header-container .title-container {
  @apply flex items-center;
}
.tests-wrapper .content-container .content-header {
  @apply flex items-center justify-between w-full px-6 border-b border-thBorder;
}

.tests-wrapper .content-container .content-body {
  @apply flex items-center justify-between w-full border-b border-thBorder;
}
.content-header .tabs-container {
  @apply flex space-x-2;
}

.content-header .tabs-container .tab-icon {
  @apply border border-thBorder rounded-full w-11 h-11 flex items-center justify-center cursor-pointer;
  @apply hover:bg-thBorder;
}

.header-container .header-button-text {
  @apply text-secondary text-sm font-bold flex px-3 justify-center whitespace-nowrap;
}
.header-container .header-button {
  @apply bg-transparent border-secondary text-secondary focus:border-secondary rounded-full py-3 border mr-2;
}
