/* screens: {
	'sm': '320px',
	'md': '768px',
	'2md': '1024px',
	'lg': '1440px',
	'xl': '1536px',
	'2xl': '1920px',
	'3xl': '2560px',
}*/

#two-column-layout-component {
  @apply w-full h-screen flex bg-white
		sm:overflow-auto sm:flex-col
		md:flex-row md:overflow-hidden;
}

#modal-content #two-column-layout-component {
  height: 80vh;
}

#two-column-layout-component .banner-column {
  @apply h-full w-full relative bg-no-repeat px-4 pt-4 justify-center
		bg-center flex flex-col bg-cover pb-4
		md:w-6/12 md:px-0 md:pt-0 md:pb-0;
}

#two-column-layout-component .banner-column .slick-dots {
  @apply text-2xs bottom-0 text-left
		sm:pl-5 md:pl-10
		lg:pl-16 xl:pl-20
		2xl:pl-24 3xl:pl-28;
}

#two-column-layout-component .banner-column .slick-dots li {
  @apply m-0
		2md:mx-0.5 lg:mx-2
		xl:mx-2.5 2xl:mx-3 3xl:mx-4;
}

#two-column-layout-component .banner-column .slick-dots li button:before {
  @apply text-transparent opacity-100 border rounded-full border-white
		w-3 h-3 sm:border-2
        lg:w-5 lg:h-5 lg:border-4
        2xl:w-6 2xl:h-6
        3xl:w-8 3xl:h-8;
}

#two-column-layout-component
  .banner-column
  .slick-dots
  li.slick-active
  button:before {
  @apply w-3 h-3 text-transparent bg-white
        lg:w-5 lg:h-5
        2xl:w-6 2xl:h-6
        3xl:w-8 3xl:h-8;
}

#two-column-layout-component .banner-column .banner-column-overlay {
  @apply absolute inset-0 bg-primary opacity-90;
}

#two-column-layout-component .column-content-logo {
  @apply w-max md:absolute md:top-0
		sm:px-5 sm:pt-5
		md:px-10 md:pt-10
		lg:px-16 lg:pt-16
		xl:px-20 xl:pt-20
		2xl:px-24 2xl:pt-24
		3xl:px-28 3xl:pt-28;
}

#two-column-layout-component .column-content-wrapper {
  @apply sm:px-5 sm:pt-5 sm:pb-10 sm:-mt-2
		md:mt-0 md:px-10 md:pt-10
		lg:px-16 lg:pt-16
		xl:px-20 xl:pt-20
		2xl:px-24 2xl:pt-24
		3xl:px-28 3xl:pt-28;
}

#two-column-layout-component .column-content-wrapper .column-content-quote {
  @apply w-6 h-6
		2md:w-8 2md:h-8
		lg:w-10 lg:h-10
		2xl:w-14 2xl:h-14
		3xl:w-16 3xl:h-16;
}

#two-column-layout-component .column-content-wrapper .column-content-text {
  @apply text-white font-light mt-2 leading-6 pb-6;
}

#two-column-layout-component .content-column {
  @apply h-full w-full overflow-hidden
		flex flex-col justify-center items-center
		md:w-6/12;
}

#two-column-layout-component .content-column .content-wrapper {
  @apply overflow-auto max-h-screen w-10/12 md:w-full sm:py-8 md:py-0;
}

#two-column-layout-component .content-column .page-wrapper {
  @apply md:w-10/12 md:m-auto overflow-hidden;
}
