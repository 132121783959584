.account-table-wrapper {
  @apply grid grid-cols-1 divide-y divide-black divide-opacity-10;
}

.account-table-container-header {
  @apply pt-5 pb-8 px-8 grid grid-flow-row-dense grid-cols-2 md:grid-cols-4 justify-between;
}

.account-table-container {
  @apply py-6.3 px-8 grid grid-flow-row-dense grid-cols-4 justify-between;
}

.account-table-content {
  @apply pl-3 col-span-2 flex flex-col;
}

.account-table-header-content {
  @apply pl-3 col-span-3 2md:col-span-2 flex flex-col;
}

.account-table-action-2 {
  @apply justify-self-end text-secondary hover:opacity-50;
}

.google-autocomplete-input__input {
  width: 100%;
}

.google-autocomplete-input__input input {
  width: inherit !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
