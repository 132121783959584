.rating-wrapper {
  @apply flex flex-row items-center;
}

.rating-svg {
  @apply relative mr-0.5
        w-3 h-3
        lg:w-5 lg:h-5
        2xl:w-6 2xl:h-6
        3xl:w-8 3xl:h-8;
}
