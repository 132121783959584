.google-login-container {
  @apply flex flex-row items-center justify-center w-full;
}

.google-login-btn {
  @apply flex items-start cursor-pointer rounded-full items-center bg-lightGray p-3 w-full md:w-full text-center;
}

.google-login-svg {
  @apply w-5 h-5 2md:w-6 2md:h-6 3xl:w-10 3xl:h-10;
}

.google-login-btn-text {
  @apply text-sm w-full text-center;
}
