.checkbox-container-custom {
  display: flex !important;
  /* align-items: center !important; */
  position: relative !important;
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container-custom input[type="checkbox"] {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
  height: 22px !important;
  width: 22px !important;
  z-index: 1 !important;
}

.checkmark {
  height: 22px !important;
  width: 22px !important;
  background-color: #ffffff !important;
  border: 1px solid #dfdfdf !important;
  border-radius: 5px !important;
  position: relative !important;
  top: 2px;
  flex: 0 0 22px;
}

.checkbox-container-custom label {
  margin-left: 14px !important;
  margin-top: 2px;
}

.checkbox-container-custom input:checked ~ .checkmark {
  background-color: #2196f3 !important;
}

.checkbox-container-custom input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  left: 5.8px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
