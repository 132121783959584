.defaultButton {
  @apply relative p-3 bg-secondary;
}

.defaultLink {
  @apply absolute p-3 px-12 py-2 text-center rounded-full bg-secondary;
}

.defaultButtonText {
  @apply font-bold text-white text-sm;
}

.hover-btn:hover {
  transition: 0.5s !important;
  @apply opacity-80;
}
