/* hover effects in toggles */
.hover-effects-left:hover {
  @apply text-black;
}

.hover-effects-right:hover {
  @apply text-black;
}

.hover-effects-white-left:hover {
  @apply text-white;
}

.hover-effects-white-right:hover {
  @apply text-white;
}

.preview-width {
  width: 120px !important;
}

span.toggle-container {
  transition: 0.3s;
  pointer-events: none;
}

span.toggle-container-billing {
  transition: 0.3s;
  pointer-events: none;
}

.pointer-effects {
  pointer-events: auto;
}

span.toggle-container:hover {
  width: 122px !important;
  transition: width 0.3s;
}

span.toggle-container-billing:hover {
  width: 47% !important;
  transition: width 0.3s;
}

span.toggle-container-left:hover {
  width: 154px !important;
  transition: width 0.3s;
}

span.toggle-container-right:hover {
  width: 135px !important;
  transition: width 0.3s;
}

.billing-h-w {
  width: 567px !important;
  height: 70px !important;
}

.billing-inner-h-w {
  width: 45% !important;
  height: 53px !important;
}

.bg-billing {
  background: #ebebeb !important;
}
